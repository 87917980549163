import { PriceGroupModel } from "@/interfaces/product";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./UpperForm.template.vue";

@Component({
  mixins: [Template],
  components: {}
})
export default class UpperFormComponent extends Vue {
  @Prop({ required: true })
  public batchLevelModel!: PriceGroupModel;
  @Prop({ default: false })
  public editing!: boolean;
  @Prop({ default: false })
  public modifyBatchLevelPricingPermission!: boolean;
}
