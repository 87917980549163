import { Location } from "@/interfaces/location";
import { MarkupRule, PriceTab } from "@/interfaces/product";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./markupPricing.template.vue";
import PercentageAdjustmentComponent from "./percentageAdjustment/PercentageAdjustment.component";

@Component({
  mixins: [Template],
  components: {
    PercentageAdjustmentComponent
  }
})
export default class MarkupPricingComponent extends Vue {
  public locationIsRecreational = false;
  public locationIsMedical = false;
  public activeTab = 0;
  public loading = false;
  public defaultRuleModel: MarkupRule = {
    id: null,
    is_active: 1,
    pricing_type: "RETAIL_MEDICAL",
    effect: "INCREASING",
    rate: 0
  };
  public tabs: PriceTab[] = [
    {
      type: "WHOLESALE_INBOUND",
      title: "product_details.wholesale_inbound",
      ruleModel: { ...this.defaultRuleModel, pricing_type: "WHOLESALE_INBOUND" }
    },
    {
      type: "WHOLESALE_OUTBOUND",
      title: "product_details.wholesale_outbound",
      ruleModel: {
        ...this.defaultRuleModel,
        pricing_type: "WHOLESALE_OUTBOUND"
      }
    }
  ];

  @Prop({ required: true })
  protected currentLocation!: Location;
  @Prop({ default: () => [] })
  protected value!: MarkupRule[];
  @Prop({ default: false })
  protected modifyBatchLevelPricingPermission!: boolean;

  public buildTabs() {
    if (this.locationIsRecreational) {
      this.tabs.unshift({
        type: "RETAIL_RECREATIONAL",
        title: "product_details.recreational_price_point",
        ruleModel: {
          ...this.defaultRuleModel,
          pricing_type: "RETAIL_RECREATIONAL"
        }
      });
    }

    if (this.locationIsMedical) {
      this.tabs.unshift({
        type: "RETAIL_MEDICAL",
        title: "product_details.medical_price_point",
        ruleModel: { ...this.defaultRuleModel, pricing_type: "RETAIL_MEDICAL" }
      });
    }
  }

  public updateRules() {
    const rules = this.tabs.map(t => t.ruleModel);
    this.$emit("input", rules);
  }

  protected setLocationTypes() {
    this.locationIsRecreational =
      this.currentLocation.location_type === "RETAIL_RECREATIONAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";

    this.locationIsMedical =
      this.currentLocation.location_type === "RETAIL_MEDICAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";
  }

  protected mounted() {
    this.setLocationTypes();
    this.buildTabs();
    // Watch for value change on edition scenario
    const unwatch = this.$watch(
      "value",
      (newVal: MarkupRule[]) => {
        this.tabs = this.tabs.map(t => {
          const rule = newVal.find(r => r.pricing_type === t.type);
          t.ruleModel = (rule && { ...rule }) || t.ruleModel;
          return t;
        });
        unwatch();
      },
      { deep: true }
    );
  }
}
