import PricepointStructureComponent from "@/components/inventory/pricePoint/pricePointStructure/PricePointStructure.component";
import { policyList } from "@/enums/permissions";
import { Location } from "@/interfaces/location";
import { PusherNotification } from "@/interfaces/notification";
import { MarkupRule, PriceGroup, PriceGroupModel } from "@/interfaces/product";
import { pusherEvents } from "@/internal";
import { pricePointService } from "@/services/pricePoint.service";
import { Callback, CallbackPromise, PageNavAction } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchLevelPricingForm.template.vue";
import MarkupPricingComponent from "./markupPricing/markupPricing.component";
import UpperFormComponent from "./upperForm/UpperForm.component";

enum PriceTypesEnum {
  RECREATIONAL = "RETAIL_RECREATIONAL",
  MEDICINAL = "RETAIL_MEDICAL",
  WHOLESALE_INBOUND = "WHOLESALE_INBOUND",
  WHOLESALE_OUTBOUND = "WHOLESALE_OUTBOUND"
}

const namespace = "BatchLevelModule";
@Component({
  mixins: [Template],
  components: {
    UpperFormComponent,
    MarkupPricingComponent,
    PricepointStructureComponent
  },
  inject: ["$changes"]
})
export default class BatchLevelPricingFormComponent extends Vue {
  @Getter("batchLevel", { namespace })
  public current!: PriceGroup;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("findBatchLevel", { namespace })
  public findBatchLevel!: Callback;
  @Action("saveBatchLevel", { namespace })
  public saveBatchLevel!: CallbackPromise<void>;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public priceTypes = PriceTypesEnum;
  public priceType: string = PriceTypesEnum.RECREATIONAL;
  public isEditing = false;
  public loading = false;
  public batchSelected = true;
  public hasModifyPermission: boolean = false;
  public batchLevelModel: PriceGroupModel = {
    name: "",
    rule_type: "MARKUP_RULE",
    markup_rules: [],
    break_rules: []
  };

  public cancel() {
    this.$router.back();
  }

  public async saveSettings() {
    this.loading = true;
    const formated: PriceGroup = {
      uid: this.batchLevelModel.uid || undefined,
      name: this.batchLevelModel.name,
      rule_type: this.batchLevelModel.rule_type,
      is_active: 1,
      is_visible: 1,
      rules:
        this.batchLevelModel.rule_type === "PRICE_BREAK_RULE"
          ? pricePointService.formatPayloadRules(
              this.batchLevelModel.break_rules
            )
          : this.batchLevelModel.markup_rules.filter(r => +r.rate > 0)
    };
    await this.saveBatchLevel(formated);
    this.loading = false;
  }

  get locationIsRecreational() {
    return (
      this.currentLocation.location_type === "RETAIL_RECREATIONAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL"
    );
  }

  get locationIsMedical() {
    return (
      this.currentLocation.location_type === "RETAIL_MEDICAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL"
    );
  }

  protected getModel(): PriceGroupModel {
    return {
      uid: this.current.uid || undefined,
      name: this.current.name,
      rule_type: this.current.rule_type,
      markup_rules:
        ((this.current.rule_type === "MARKUP_RULE" &&
          this.current.rules) as MarkupRule[]) || [],
      break_rules:
        (this.current.rule_type === "PRICE_BREAK_RULE" && this.current.rules) ||
        []
    };
  }

  protected async loadBatchLevel() {
    if (this.$route.params.id && !this.batchLevelModel.id) {
      this.isEditing = true;
      this.loading = true;
      await this.findBatchLevel(this.$route.params.id);
      this.batchLevelModel = this.getModel();
      this.loading = false;
    }
    this.priceType = this.locationIsRecreational
      ? PriceTypesEnum.RECREATIONAL
      : PriceTypesEnum.MEDICINAL;
  }

  protected mounted() {
    if (this.$route.name === "batch-level-view") {
      this.hasModifyPermission = true;
    }
    this.setPageNav({
      title: "batch_level_pricing.title",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.saveSettings,
            vuetifyProps: () => ({
              disabled: !this.batchLevelModel.name,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel
          }
        ]
      }
    });
    this.loadBatchLevel();
    this.$changes.watch(
      pusherEvents.batchPriceLevelTouched,
      this.loadBatchLevel,
      (data: PusherNotification) => {
        return !!data.message.find(n => n.item_id === +this.$route.params.id);
      }
    );
  }
}
