import { MarkupRule } from "@/interfaces/product";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PercentageAdjustment.template.vue";
@Component({
  mixins: [Template],
  components: {}
})
export default class PercentageAdjustmentComponent extends Vue {
  @Prop({ required: true })
  public value!: MarkupRule;
  @Prop({ default: false })
  public modifyPermission!: boolean;
  public adjustmentType: string[] = ["DECREASING", "INCREASING"];

  get ruleModel() {
    return cloneDeep(this.value);
  }

  public updateRule() {
    this.$emit("input", this.ruleModel);
  }

  public validateNumber = (event: KeyboardEvent) => {
    const numbers = /^\d|\.|,$/;
    if (!event.key.match(numbers)) {
      event.preventDefault();
    }
  };
}
